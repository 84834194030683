// CSS
import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENT
import Main from './components/main/Main';
import Menu from './components/menu/Menu';
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
import Topbar from './components/topbar/Topbar';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

// CONTEXT
import { AppProvider } from './components/context';

// MODULE
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <AppProvider>
      <div className='app'>
        <Topbar />
        <Menu />
        <div className='sections'>
          <Main />
          <About />
          <Portfolio />
          <Contact />
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </AppProvider>
  );
}

export default App;
