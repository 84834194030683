// CSS
import './menu.scss';

// DATA
import { menuList } from '../../data/MenuList';

// CONTEXT
import { useGlobalContext } from '../context';

// COMPONENT
import MenuItem from '../menuItem/MenuItem';

export default function Menu() {
  const { menuOpen } = useGlobalContext();

  return (
    <nav className={menuOpen ? 'active' : ''}>
      <ul>
        {menuList.map((menu, index) => {
          return (
            <li key={index}>
              <MenuItem item={menu.item} id={menu.id} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
