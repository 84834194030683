// CSS
import './footer.scss';

// CONTEXT
import { useGlobalContext } from '../context';

// COMPONENT
import MyModal from '../mymodal/MyModal';

// DATA
import { footerItems } from '../../data/FooterItems';

export default function Footer() {
  const { setIsModalOpen, setModalItem } = useGlobalContext();

  const openModal = ({ icon, text }) => {
    setModalItem({ icon, text });
    setIsModalOpen(true);
  };

  const footerIcons = footerItems.map(({ icon, text, id, url }) => {
    return (
      <div
        className='iconContainer'
        key={id}
        onClick={() => {
          if (id === 1 || id === 5) openModal({ icon, text });
          else window.open(url);
        }}
      >
        {icon}
        <span>{text}</span>
      </div>
    );
  });

  return (
    <footer>
      <div className='footerIconsContainer'>{footerIcons}</div>
      <MyModal />
    </footer>
  );
}
