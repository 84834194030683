// CSS
import './bullet.scss';

// DATA
import { projects } from '../../data/DataProject';

export default function Bullet({ currentSlide, setCurrentSlide }) {
  return (
    <div className='bullet-container'>
      {projects.map((item, index) => {
        return (
          <div
            className={currentSlide === index ? 'bullet active' : 'bullet'}
            onClick={() => setCurrentSlide(index)}
            key={index}
          >
            {index + 1}
          </div>
        );
      })}
    </div>
  );
}
