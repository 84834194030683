// CSS
import './menuItem.scss';

// CONTEXT
import { useGlobalContext } from '../context';

export default function MenuItem({ item, id }) {
  const { menuOpen, setMenuOpen } = useGlobalContext();

  return (
    <a href={id} onClick={() => setMenuOpen(!menuOpen)}>
      {item}
    </a>
  );
}
