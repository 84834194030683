// CSS
import './mymodal.scss';

// COMPONENT
import Modal from 'react-modal/lib/components/Modal';

// CONTEXT
import { useGlobalContext } from '../context';

// MODULE
import { toast } from 'react-toastify';

// MUI
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

export default function MyModal() {
  const { isModalOpen, setIsModalOpen, modalItem } = useGlobalContext();
  const { icon, text } = modalItem;

  const copyToClipboard = (e) => {
    let copyText = text.replace(/\s+/g, '');
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.info(`${text} has copied to clipboard`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error('Something went wrong', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={customStyles}
    >
      <div className='modal-container'>
        <div className='button-container'>
          <div className='close-button' onClick={() => setIsModalOpen(false)}>
            <CloseOutlinedIcon />
          </div>
          <div className='copy-button' onClick={copyToClipboard}>
            <ContentCopyOutlinedIcon />
          </div>
        </div>
        <div className='modal-content'>
          {icon}
          <span>{text}</span>
        </div>
      </div>
    </Modal>
  );
}
