//CCS
import './topbar.scss';

// CONTEXT
import { useGlobalContext } from '../context';

export default function Topbar() {
  const { menuOpen, setMenuOpen } = useGlobalContext();

  return (
    <div className={menuOpen ? 'active topbar' : 'topbar'}>
      <div className='wrapper'>
        <div className='left'>
          <a href='#main' className='logo'>
            israelquimson.com
          </a>
        </div>
        <div className='right'>
          <div className='hamburger' onClick={() => setMenuOpen(!menuOpen)}>
            <span className='line1'></span>
            <span className='line1'></span>
            <span className='line1'></span>
          </div>
        </div>
      </div>
    </div>
  );
}
