// CSS
import './slider.scss';

// DATA
import { projects } from '../../data/DataProject';

// MODULE
import { useSwipeable } from 'react-swipeable';

// REACT MODULE
import { useEffect } from 'react';

const config = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
};

export default function Slider({ currentSlide, arrowMove }) {
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => arrowMove('left'),
    onSwipedRight: (eventData) => arrowMove('right'),
    ...config,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      arrowMove('right');
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [arrowMove]);

  const renderSlide = projects.map(
    ({ id, image, color, name, link, title, description }) => {
      return (
        <div className='container' key={id} style={{ backgroundColor: color }}>
          <article className='project'>
            <div className='imgContainer'>
              <img
                src={image}
                alt={name}
                className={link ? 'clickable' : ''}
                onClick={() => {
                  link && window.open(link, '_blank');
                }}
              />
            </div>
            <div className='textContainer'>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </article>
        </div>
      );
    }
  );

  return (
    <div
      className='slider'
      style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      {...handlers}
    >
      {renderSlide}
    </div>
  );
}
