// CSS
import './contact.scss';

// MUI
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';

// MODULE
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

// REACT HOOK
import { useRef } from 'react';

export default function Contact() {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_0izpmkh',
        'template_0o2qnte',
        form.current,
        'AxlbkG4d5HdBWwGGP'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    toast.success('Message has been send', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    e.target.reset();
  };

  return (
    <div className='contact-container' id='contact'>
      <div className='message-container'>
        <form onSubmit={handleSubmit} ref={form}>
          <div className='input-container'>
            <label htmlFor='name'>
              <p>
                Hi
                <EmojiPeopleOutlinedIcon className='contact-icon' />
                What's your name?
              </p>
            </label>
            <input
              className='text-input'
              type='text'
              placeholder='Name'
              id='clientName'
              name='clientName'
              required
            />
          </div>
          <div className='input-container'>
            <label htmlFor='email'>
              <p>
                What is your email?
                <EmailIcon className='contact-icon' />
              </p>
            </label>
            <input
              className='text-input'
              type='email'
              placeholder='Email'
              id='email'
              name='email'
              required
            />
          </div>
          <div className='input-container'>
            <label htmlFor='message'>
              <p>
                Your Inquire?
                <MessageIcon className='contact-icon' />
              </p>
            </label>
            <textarea
              className='text-input'
              id='message'
              name='message'
              placeholder='Message'
              maxLength={2000}
              spellCheck='true'
              required
              style={{ resize: 'none' }}
            ></textarea>
          </div>
          <button type='submit' className='submit' value='Send'>
            submit
          </button>
        </form>
      </div>
    </div>
  );
}
