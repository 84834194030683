// CSS
import './about.scss';

export default function Portfolio() {
  return (
    <section className='about hero' id='about'>
      <h1>Hi, my name is Israel Quimson</h1>
      <p>
        I am a technology enthusiast who enjoys solving problems. I currently
        work as network security analyst and have taught myself programming,
        with a focus on web scraping and web development as hobbies. I have a
        passion for constantly improving and optimizing systems and I always on
        the lookout for new ways to innovate and push the boundaries of what is
        possible in the digital world. With my keen attention to detail, drive
        to succeed, and passion for technology, I am excited to continue making
        significant contributions in the industry and inspire others to pursue
        their own innovative pursuits.
      </p>
    </section>
  );
}
