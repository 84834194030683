import SettingsCellRoundedIcon from '@mui/icons-material/SettingsCellRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const footerItems = [
  { id: 1, icon: <SettingsCellRoundedIcon />, text: '+63 976 079 4558' },
  {
    id: 2,
    icon: <GitHubIcon />,
    text: 'blufips',
    url: 'https://github.com/blufips',
  },
  {
    id: 3,
    icon: <LinkedInIcon />,
    text: 'Linkedin',
    url: 'https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile',
  },
  {
    id: 4,
    icon: <PictureAsPdfRoundedIcon />,
    text: 'CV',
    url: 'media/IsraelQuimsonCV.pdf',
  },
  { id: 5, icon: <EmailRoundedIcon />, text: 'israelquimson49056@yahoo.com' },
];
