// CSS
import './portfolio.scss';

// COMPONENT
import Slider from '../slider/Slider';
import Bullet from '../bullet/Bullet';

// DATA
import { projects } from '../../data/DataProject';

// MUI
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

// REACT HOOK
import { useState } from 'react';

export default function Portfolio() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const arrowMove = (direction) => {
    if (direction === 'left') {
      if (currentSlide === 0) return setCurrentSlide(projects.length - 1);
      setCurrentSlide(currentSlide - 1);
    }
    if (direction === 'right') {
      if (currentSlide === projects.length - 1) return setCurrentSlide(0);
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <div className='portfolio' id='portfolio'>
      <Slider currentSlide={currentSlide} arrowMove={arrowMove} />
      <ArrowLeftOutlinedIcon
        className='arrow left'
        onClick={() => arrowMove('left')}
      />
      <ArrowRightOutlinedIcon
        className='arrow right'
        onClick={() => arrowMove('right')}
      />
      <Bullet currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
    </div>
  );
}
