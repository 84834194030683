export const projects = [
  {
    id: 1,
    color: '#FF6542',
    image: 'media/todaysphnews.png',
    name: 'todayph',
    link: 'https://todayph.news/',
    title: 'TodayPhNews',
    description:
      "todayph.news is a reliable source of daily news updates in the Philippines, providing up-to-date information on various topics such as politics, entertainment, and sports. One of the key features of todayph.news is its use of web scraping techniques, which allows it to gather news information from various sources and present it to readers in one convenient platform. Behind the scenes, todayph.news uses Django REST API as its backend network, enabling smooth communication between the front-end and back-end components of the website. Users can access the website's user-friendly interface, built with React and TypeScript, to navigate through the latest news articles, read in-depth analysis, and even share their own opinions through comments and discussions.",
  },
  {
    id: 2,
    color: '#5b8',
    image: 'media/wanderingph.jpg',
    name: 'wandering',
    link: '',
    title: 'WanderingPh',
    description:
      "Wandering Philippines is a forum and blog website that focuses on answering a variety of travel-related queries in various locations across the Philippines. Built using Django, HTML, CSS, JavaScript, and jQuery, it provides a user account system that enables users to upload or delete photos, videos, and links related to their travel experiences. Django's template system allows for efficient rendering of dynamic web pages, creating a modern and visually appealing website. Overall, Wandering Philippines is a comprehensive platform for travelers seeking information, interaction, and engagement.",
  },
  {
    id: 3,
    color: '#85b',
    image: 'media/mangaupdate.jpg',
    name: 'mangaupdate',
    link: '',
    title: 'MangaUpdate',
    description:
      "Mangaupdate is an innovative application designed for manga enthusiasts who want to stay updated on their favorite manga series. This application has a user-friendly interface and is built using Python's GUI Kivy, making it available for cross-platform use on Windows, Linux, and Android. One of the most significant features of Mangaupdate is its ability to check all of the user's favorite manga series' updates, starting from the most recent and redirecting the browser to the manga server. This feature ensures that users never miss out on updates on their favorite manga series.",
  },
];
