// CSS
import './main.scss';

// MODULE
import { init } from 'ityped';

// REACT HOOK
import { useEffect, useRef } from 'react';

export default function Main() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      strings: ['Freelance Web Developer', 'Network Security Analyst'],
      backDelay: 1500,
      backSpeed: 60,
    });
  }, []);

  return (
    <main id='main'>
      <div className='top'>
        <div className='imgContainer'>
          <img src='media/me.png' alt='me' />
        </div>
      </div>
      <div className='bottom'>
        <div className='wrapper'>
          <h2>Hi, I'm</h2>
          <h1>Israel Quimson</h1>
          <h3>
            &nbsp;<span ref={textRef}></span>
          </h3>
        </div>
      </div>
    </main>
  );
}
